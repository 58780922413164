<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-img
          v-if="item.imgname"
          :src="'/img/produtos/' + item.imgname"
          class="fill-height"
        >
          <div class="pa-3 tituloProduto">
            <v-icon small color="white" left>fas fa-{{ item.icon }}</v-icon>
            <span class="subtitle-1 font-weight-bold white--text">
              {{ item.titulo }}
            </span>
          </div>
        </v-img>
      </v-col>
    </v-row>
    <v-row class="pa-1">
      <v-col cols="12">
        <v-stepper v-model="getcStep" vertical class="elevation-0 pa-1">
          <template v-for="(item, index) in steps">
            <v-stepper-step
              :key="index + '-step'"
              :complete="getcStep > index + 1"
              :step="index + 1"
              color="primary"
              complete-icon="fas fa-check"
              v-if="getcStep == index + 1 || item.respondido"
            >
              <span
                :class="
                  getcStep == index + 1
                    ? 'black--text font-weight-bold subtitle-1'
                    : 'body-2'
                "
                >{{ item.descricao }}</span
              >
              <span class="caption font-weight-bold" v-if="item.respondido">
                {{ item.respondido }}
              </span>
            </v-stepper-step>

            <v-stepper-content
              :step="index + 1"
              :key="index + '-stepcontent'"
              :class="getcStep == index + 1 ? '' : 'd-none'"
            >
              <v-row dense>
                <template v-if="index == 0">
                  <v-col cols="6">
                    <v-btn
                      outlined
                      color="primary"
                      block
                      @click="addCstep(item, 1, 'Sim')"
                    >
                      <v-icon small left>fas fa-check</v-icon>Sim
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      outlined
                      color="grey grey--text"
                      block
                      @click="addCstep(item, 2, 'Não')"
                    >
                      <v-icon small left>fas fa-times</v-icon>Não
                    </v-btn>
                  </v-col>
                </template>

                <template v-if="index == 1">
                  <v-col cols="12" class="mt-5 pt-5">
                    <v-slider
                      color="primary"
                      v-model="qtdDependentes"
                      step="1"
                      thumb-label="always"
                      min="0"
                      max="10"
                    >
                      <template v-slot:prepend>
                        <v-icon color @click="qtdDependentes--"
                          >fas fa-minus</v-icon
                        >
                      </template>

                      <template v-slot:append>
                        <v-icon @click="qtdDependentes++">fas fa-plus</v-icon>
                      </template>
                    </v-slider>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      outlined
                      color="primary"
                      @click="
                        addCstep(item, 1, qtdDependentes + ' dependente(s)')
                      "
                      block
                      v-if="qtdDependentes > 0"
                    >
                      <v-icon small left>fas fa-check</v-icon>Continuar
                    </v-btn>
                  </v-col>
                </template>

                <template v-if="index == 2">
                  <v-col cols="6">
                    <v-btn
                      outlined
                      color="primary"
                      block
                      @click="addCstep(item, 1, 'Apartamento')"
                      class="pa-2"
                    >
                      <v-icon small left>fas fa-hospital</v-icon>Apartamento
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      outlined
                      color="primary"
                      block
                      @click="addCstep(item, 1, 'Enfermaria')"
                      class="pa-2"
                    >
                      <v-icon small left>fas fa-clinic-medical</v-icon
                      >Enfermaria
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-stepper-content>
          </template>
        </v-stepper>
      </v-col>

      <template v-if="getcStep > steps.length">
        <v-col cols="12">
          <v-divider class="mx-0 my-1"></v-divider>
          <v-list
            :two-line="true"
            :rounded="true"
            :avatar="true"
            color="transparent"
          >
            <template v-for="(plano, index) in planosSorted()">
              <v-list-item
                :key="'list-item' + index"
                v-if="
                  ((plano.empresa && $auth.isAuthenticated()) ||
                    !plano.empresa) &&
                    (!cplano || cplano == plano)
                "
                @click="selectPlano(plano)"
              >
                <v-list-item-avatar>
                  <v-img :src="'/img/logos/' + plano.img"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ plano.descricao }}</v-list-item-title>
                  <v-list-item-subtitle
                    >Plano: {{ plano.plano }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle v-if="plano.empresa"
                    >Concedido por&nbsp;{{
                      $auth.user().name
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text class="font-weight-bold black--text"
                    >R$ {{ plano.preco }}</v-list-item-action-text
                  >
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-col>

        <template v-if="cplano">
          <v-col cols="12">
            <v-btn outlined color="primary" block @click="addCarrinho()">
              <v-icon small left>fas fa-shopping-cart</v-icon>Colocar no
              carrinho
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" block @click="finalizarCarrinho()">
              <v-icon small left>fas fa-wallet</v-icon>Finalizar Compra
            </v-btn>
          </v-col>
        </template>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      cplano: null,
      planos: [
        {
          descricao: "Caixa",
          plano: "Vital",
          img: "logoCaixaSaude.jpg",
          preco: "420,00"
        },
        {
          descricao: "Amil",
          plano: "Next",
          img: "logoAmilSaude.jpg",
          preco: "380,00",
          empresa: true
        },
        {
          descricao: "Sulamérica",
          plano: "Exato",
          img: "logoSulamericaSaude.jpg",
          preco: "434,00"
        },
        {
          descricao: "Bradesco",
          plano: "TPPE",
          img: "logoBradescoSaude.jpg",
          preco: "490,00"
        },
        {
          descricao: "Unimed",
          plano: "Básico",
          img: "logoSegurosUnimed.jpg",
          preco: "435,00",
          empresa: true
        },
        {
          descricao: "Grupo NotreDame Intermédica (GNDI)",
          plano: "Básico",
          img: "logoGNDI.jpg",
          preco: "390,00"
        }
      ],
      steps: [
        {
          descricao: "Possui dependentes?",
          respondido: false
        },
        {
          descricao: "Quantos dependentes?",
          respondido: false
        },
        {
          descricao: "Qual o tipo de acomodação?",
          respondido: false
        }
      ],
      item: {},
      cStep: 1,
      qtdDependentes: 1
    };
  },
  mounted() {
  },
  props: {
    id: null
  },
  methods: {
    ...mapMutations(["updateBadges", "updateMainSearch"]),
    planosSorted() {
      return this.planos.slice().sort((a, b) => {
        return a.descricao.localeCompare(b.descricao);
      });
    },
    addCstep(item, n, resposta) {
      item.respondido = resposta ? resposta : true;
      this.getcStep = this.getcStep + n;
    },
    addCarrinho(item) {
      this.updateMainSearch("");
      this.updateBadges({ carrinho: this.badges.carrinho + 1 });
      this.$util.link("produtos");
    },
    finalizarCarrinho(item) {
      if (this.$auth.isAuthenticated()) {
        this.addCarrinho(item);
      } else {
        this.$util.link("login");
      }
    },
    selectPlano(plano) {
      if (this.cplano == plano) this.cplano = null;
      else this.cplano = plano;
    }
  },
  computed: {
    ...mapState(["badges"]),
    getcStep: {
      get: function() {
        return this.cStep;
      },
      set: function(value) {
        this.cStep = value;
      }
    }
  }
};
</script>

<style>
/* stepper style */
.v-stepper__content {
  border: 0 !important;
  margin-left: 0 !important;
}
.v-stepper__step--active > span,
.v-stepper__step--inactive > span {
  display: none;
}
.theme--light.v-stepper {
  background-color: transparent !important;
}
.v-stepper__step,
.v-stepper__content {
  padding-left: 5px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/*  */
.tituloProduto {
  background-color: var(--v-primary);
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>
